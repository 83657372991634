import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Card } from 'reactstrap';

import { SEO } from '@components';
import { BlogEntryProps } from '@types';

const Manifest = ({ includeSeo }: BlogEntryProps) => (
  <StaticQuery
    query={graphql`
      query {
        file(name: {eq: "SandStoneGarden"}) {
          publicURL
        }
      }
    `}
    render={({ file: { publicURL } }) => (
      <>
        {includeSeo && (
          <SEO
            description={'Have you ever felt like you weren\'t manifesting your truest potential? Stop putting off what you want and start working towards it today.'}
            image={publicURL}
            slug="manifest"
            title="Manifest who you want to be"
          />
        )}
        <Card className="px-0">
          <img
            alt="The Sand and Stone Garden at the Portland Japanese Garden"
            className="d-block mb-2 mx-auto"
            src={publicURL}
          />
          <h2 className="mb-0 mt-3 text-center">Manifest</h2>
          <span className="mb-3 text-center">23 October 2019</span>
          <div className="body mx-auto px-2 px-sm-0">
            <h3>The end?</h3>
            <p>
              I&apos;m done being a recording engineer. I still use that skillset, but that role is not for me anymore. My social media has mixed messaging and I want to clear them up. A great deal of where I am today is owed to recording and mixing music. That path has brought me a lot of great music, memories, and friendships. I really value the perspective afforded to me, and I hope to keep giving back in any way I can. I got what I needed from that path, and I think I understand why I chose it. The choice to go into recording/mixing seems like an unconscious decision; when the opportunities presented themselves, and I jumped wholeheartedly, but lately it&apos;s felt like I was running away from my true intentions. From the start of piano lessons in 4th grade, music performance is really been the thing that&apos;s always propelled me forward. The words eluded me at the time, but pulling strange shapes made out of sound and manifesting them back into reality is what it feels like to be grounded in myself.
            </p>
            <p>
              As a recording engineer, my focus was centered around helping people capture their best performance, but in almost every session, I remember feeling like I should be producing instead of recording. I knew better than to overstep my boundaries, so I kept most of my input to myself. I don&apos;t know that I&apos;ve earned enough gravitas to go around ripping apart other people&apos;s songs and really push them towards the human limit. I don&apos;t know that I trust myself enough with my own music. I don&apos;t know what exactly am I doing? I guess that&apos;s the point of this post, a public declaration that I&apos;m jumping into vulnerability and persistence. Doing it is gonna kick my ass, but I guess that&apos;s what I want.
            </p>
            <hr />
          </div>
        </Card>
      </>
    )}
  />
);

export default Manifest;
